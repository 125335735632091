<script setup lang="ts">
import { type Content } from "@prismicio/client";
import { vElementVisibility } from "@vueuse/components";

const props = defineProps(
  getSliceComponentProps<Content.LogoHighlightSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ]),
);
const { primary } = useSlice(props);

const isPageLoaded = ref(false);
const isSliceVisible = ref(false);
const { localeProperties } = useI18n();

onNuxtReady(() => {
  isPageLoaded.value = true;
});
const onElementVisibility = (state: boolean) => {
  isSliceVisible.value = state;
};
</script>

<template>
  <section
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="h-dvh w-full p-4"
    v-element-visibility="onElementVisibility"
  >
    <div
      class="flex h-full flex-col items-center justify-center opacity-0 transition-all duration-[1.5s]"
      :class="[{ ['opacity-100']: isPageLoaded && isSliceVisible }]"
    >
      <span
        class="typo-logo text-[min(25vw,600px)] font-bold leading-[min(25vw,600px)] text-black"
        >ASK
      </span>
      <RichText :text="primary.title" class="mt-10 text-xl -sm:text-base" />
      <RichText
        :text="primary.description"
        class="mt-4 max-w-[700px] text-center text-xs text-gray-600"
      />

      <div class="mt-8 w-full max-w-[300px]">
        <BButton
          size="md"
          class="block !w-full"
          :link-object="{
            link_type: 'Document',
            type: 'project_hub',
            id: 'project_hub',
            lang: localeProperties.language,
          }"
        >
          ENTER
        </BButton>
      </div>
    </div>
  </section>
</template>
